import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Button} from "semantic-ui-react";

export default function ContingentButton(props) {
    const [requiredPermissionUrn, setRequiredPermissionUrn] = useState("");
    const [userHasRequiredPermissions, setUserHasRequiredPermissions] = useState(false);

    useEffect(function updateRequiredPermissionUrn() {
        let permissionUrn;

        for (const permission of props.allPermissions) {
            const serviceMatches = props.service === permission.service;
            const moduleMatches = props.module === permission.module;
            const scopeMatches = props.scope === "any" || (Array.isArray(props.scope) && props.scope.concat("all").includes(permission.scope)) || props.scope === permission.scope || permission.scope === "all";
            const roleMaches = props.role === permission.role;
            console.log("ContingentButton.updateRequiredPermissionUrn: urn: ", permission.urn, "service matches? ", serviceMatches, "module matches? ", moduleMatches, "scope matches? ", scopeMatches, "props.scope", props.scope, "permission.scope", permission.scope, "role matches?", roleMaches);
            if (serviceMatches && moduleMatches && scopeMatches && roleMaches) {
                permissionUrn = permission.urn;
            }
        }

        setRequiredPermissionUrn(permissionUrn);
    }, [JSON.stringify(props.allPermissions), props.service, props.module, props.scope, props.role]);

    useEffect(function () {
        let updatedUserHasRequiredPermissions = false;
        for (const authz of props.user.authz) {
            if (requiredPermissionUrn === authz || authz === "urn:all:aspen-power") {
                updatedUserHasRequiredPermissions = true;
                break;
            }
        }

        setUserHasRequiredPermissions(updatedUserHasRequiredPermissions);
    }, [JSON.stringify(props.user), requiredPermissionUrn]);

    return (
        <Button
            {...props}
            style={{visibility: userHasRequiredPermissions ? "visible" : props.hideIfNotPermitted === true  ? "hidden" : ""}}
            disabled={props.disabled || !userHasRequiredPermissions}
        />
    );
};

ContingentButton.propTypes = {
    allPermissions: PropTypes.array.isRequired,
    hideIfNotPermitted: PropTypes.bool,
    user: PropTypes.object.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    scope: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    role: PropTypes.string
};

ContingentButton.defaultProps = {
    role: "editor",
    hideIfNotPermitted: false
};
