import React, {Component} from "react";
import JSONEditor from "jsoneditor";
import StateBasedPlayDataProvider from "../../Services/StateBasedPlayDataProvider";
import {Button, Form, Grid, Icon, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default class SchemaEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schema: props.schema,
            test: {},
            successMessage: "",
            errorMessages: []
        };
    }

    componentDidMount() {
        this.schemaEditor = new JSONEditor(this.schemaContainer, {
            mode: 'text',
            onChangeText: jsonString => {
                if (jsonString !== JSON.stringify(this.state.schema)) {
                    console.log("SchemaEditor.componentDidMount.onChangeText jsonString, schema, and editortext? ", jsonString, this.props.schema, this.state.schema);
                    try {
                        this.setState({schema: JSON.parse(jsonString)});
                        this.props.setSchema(this.state.schema);
                    } catch (e) {
                        console.error("SchemaEditor.componentDidMount.onChangeText) error with json.parse on ", jsonString);
                    }
                }
            }
        });
        this.schemaEditor.set(this.state.schema);
        this.testEditor = new JSONEditor(this.testContainer, {
            mode: 'text',
            onChangeText: jsonString => {
                if (jsonString !== JSON.stringify(this.state.test)) {
                    console.log("SchemaEditor.componentDidMount.onChangeText jsonString, text json? ", jsonString, this.state.test);
                    try {
                        this.setState({test: JSON.parse(jsonString)});
                    } catch (e) {
                        console.error("SchemaEditor.componentDidMount.onChangeText) error with json.parse on ", jsonString);
                    }
                }
            }
        });
        this.testEditor.set(this.state.test);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.schema !== prevProps.schema)  {
            console.log("SchemaEditor.componentDidUpdate schema props updated, schema props, and schema state? ", this.props.schema, this.state.schema);
            if (this.props.schema !== this.state.schema) {
                this.setState({schema: this.props.schema});
            }
        }
        if (this.state.schema !== prevState.schema) {
            console.log("SchemaEditor.componentDidUpdate schema props updated, schema props, and schema state? ", this.props.schema, this.state.schema);
            this.schemaEditor.set(this.state.schema);
            this.props.setSchema(this.state.schema);
        }

        if (this.state.test !== prevState.test) {
            console.log("SchemaEditor.componentDidUpdate test json updated, test json? ", this.state.test);
            this.testEditor.set(this.state.test);
        }
    }

    onClickTest = () => {
        this.setState({successMessage: "", errorMessages: []});

        StateBasedPlayDataProvider.testSchema(this.state.schema, this.state.test).then(response => {
            console.log(response);
            if (response.hasOwnProperty("context")) {
                this.setState({errorMessages: JSON.stringify(response, null, 4)});
            } else if (response.hasOwnProperty("error")) {
                this.setState({errorMessages: [response.error.toString()]});
            } else {
                this.setState({successMessage: "The schema is valid and the test data matches it."});
            }
        }).catch(error => {
            console.error(error);
            this.setState({errorMessages: [error.toString()]});
        });
    };

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button floated="left" as={Link} target="_blank" to={{pathname: "https://json-schema.org/"}}>Help</Button>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button floated="right" onClick={this.onClickTest}>Test Schema</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        {
                            this.state.successMessage ?
                                <Message icon color="green">
                                    <Icon name="check" />
                                    <Message.Content>
                                        {this.state.successMessage}
                                    </Message.Content>
                                </Message> :
                                this.state.errorMessages.length > 0 ?
                                    <Message icon color="red">
                                        <Icon name="x" />
                                        <Message.Content>
                                            <pre>{this.state.errorMessages}</pre>
                                        </Message.Content>
                                    </Message> : ""
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <div style={{height: "57vh"}} ref={elem => this.schemaContainer = elem} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div style={{height: "57vh"}} ref={elem => this.testContainer = elem} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
