import React, {useState, useEffect, Component, useLayoutEffect} from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import {Container, Grid, Icon, Message} from "semantic-ui-react";

export default function SecureRoute(props) {
    const [isAuthenticated, setIsAuthenticated] = useState("initializing");

    useEffect(() => {
        console.log("SecureRoute.updateProps props: ", props);
    }, [JSON.stringify(props)]);

    useLayoutEffect(() => {
        console.log("SecureRoute props: ", props);
        props.setPathname(props.path);
        return () => {
            props.setPathname("/");
        }
    }, [JSON.stringify(props)]);

    useEffect(function scrollToTopOnInitialization() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(function determineAuthorization() {
        let { user, authState, service, module, open, userPermissions, loadingPermissions } = props;
        if (user !== undefined && user.hasOwnProperty("authz") && Array.isArray(user.authz) && loadingPermissions !== "UNINITIALIZED" && Array.isArray(userPermissions) && userPermissions.length > 0) {
            console.log("(SecureRoute useEffect getAuthorization) user, authState: ", user, authState);
            let userHasRequiredPermissions = open;
            if (!userHasRequiredPermissions) {
                for (const permission of userPermissions) {
                    if (["all", service].includes(permission.service) && ["all", module].includes(permission.module)) {
                        userHasRequiredPermissions = true;
                        break;
                    }
                }
            }

            let authenticated = user && authState !== "UNAUTHORIZED" && userHasRequiredPermissions;
            console.log("(SecureRoute useEffect getAuthorization) setting isAuthenticated: ", authenticated);
            setIsAuthenticated(authenticated);
        }
    }, [isAuthenticated, JSON.stringify(props.user), props.authState, JSON.stringify(props.userPermissions), props.open, props.service, props.module, props.loadingPermissions]);

    const Component = props.component;

    if (props.loadingPermissions) {
        return (
            <Grid className="masterContainer">
                <Container>
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>
                            Loading...
                        </Message.Content>
                    </Message>
                </Container>
            </Grid>
        );
    }

    if (isAuthenticated === true) {
        console.log("(SecureRoute render) authState was AUTHORIZED and user had the correct permissions, rendering: ", props.component.name);
        return <Route
            path={props.path}
            exact={props.exact === true}
            render={routeProps => <Component {...routeProps} {...props} />}
        />
    } else if (isAuthenticated === false) {
        if (props.authState === "AUTHORIZED") {
            console.log("(SecureRoute render) authState was AUTHORIZED, redirecting to /unauthorized: isAuthenticated, authState", isAuthenticated, props.authState);
            return <Redirect push to="/unauthorized" {...props} />;
        } else {
            console.log("(SecureRoute render) user, isAuthenticated, authState", props.user, isAuthenticated, props.authState);
            return <br />
        }
    } else {
        console.log("(SecureRoute render) user, isAuthenticated, authState", props.user, isAuthenticated, props.authState);
        return <br />
    }
}

SecureRoute.propTypes = {
    component: PropTypes.objectOf(React.Component) || PropTypes.func,
    path: PropTypes.string.isRequired,
    getAllPotentialProperties: PropTypes.func,
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    open: PropTypes.bool,
    authenticated: PropTypes.bool.isRequired,
    listToDatalist: PropTypes.func,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired
};

SecureRoute.defaultProps = {
    open: false
};
