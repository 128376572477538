import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Menu, Container, Image, Dropdown, Search, Popup, Icon, List, Grid, Label, Breadcrumb} from "semantic-ui-react";
import logo from './images/synapse-logo.png';
import styles from './Navbar.css';
import { Link } from "react-router-dom";
import AuthDataProvider from "../../Services/AuthDataProvider";

export default function Navbar(props) {
    const [givenName, setGivenName] = useState("");
    const [environment, setEnvironment] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [serviceGroups, setServiceGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedService, setSelectedService] = useState("");

    useEffect(function initEnvironment() {
        setEnvironment(process.env.ENVIRONMENT_NAME);
    }, []);

    useEffect(function updateAuthorizedServiceGroups() {
        console.log(`(Navbar.updateAuthorizedServiceGroups) updating authorized service groups`);
        const authorizedServiceGroups = [];
        if (props.user && props.serviceGroups) {
            for (const serviceGroup of props.serviceGroups) {
                const authorizedServiceGroup = {};
                authorizedServiceGroup.groupName = serviceGroup.groupName;
                authorizedServiceGroup.services = [];
                for (const service of serviceGroup.services.slice()) {
                    if (AuthDataProvider.userHasRequiredPermission(props.user.authz, props.permissions, service.open || false, service.service, service.module) === true) {
                        authorizedServiceGroup.services.push(Object.assign({}, service));
                    }
                }
                // authorizedServiceGroup.services = serviceGroup.services.filter(service => AuthDataProvider.userHasRequiredPermission(service.open, service.service, service.module) === true);
                if (authorizedServiceGroup.services.length > 0) {
                    authorizedServiceGroups.push(authorizedServiceGroup);
                }
            }
        }
        console.log("Navbar.updateAuthorizedServiceGroups: authorized service groups: ", authorizedServiceGroups);
        setServiceGroups(authorizedServiceGroups);
    }, [JSON.stringify(props.serviceGroups), JSON.stringify(props.user), JSON.stringify(props.permissions)]);


    useLayoutEffect(function updateSelectedService() {
        let updatedSelectedService = "";
        let updatedSelectedGroup = "";
        const pathname = props.pathname.split("/")[1];
        for (const group of props.serviceGroups) {
            for (const service of group.services) {
                console.log(service.path, pathname);
                if (service.path === `/${pathname}`) {
                    updatedSelectedGroup = group.groupName;
                    updatedSelectedService = service.name;
                    break;
                }
            }
        }

        setSelectedGroup(updatedSelectedGroup);
        setSelectedService(updatedSelectedService);

        // on unmount
        return () => {
            props.setPathname(window.location.pathname);
        }
    }, [JSON.stringify(props.serviceGroups), props.pathname]);

    useEffect(function updateSearchResults() {
        const services = [];
        for (const group of serviceGroups) {
            for (const service of group.services) {
                if (service.name.toLowerCase().includes(searchFilter.toLowerCase()) || service.description.toLowerCase().includes(searchFilter.toLowerCase())) {
                    services.push(service);
                }
            }
        }
        setSearchResults(services);
    }, [JSON.stringify(serviceGroups), searchFilter]);

    useEffect(() => {
        if (props.user && props.user.hasOwnProperty("name")) {
            const delimitedName = props.user.name.split(",");
            setGivenName(delimitedName.length > 1 ? props.user.name.split(",")[1] : props.user.name);
        }
    }, [props.user]);

    return (
        <Container fluid>
            <Menu fixed="top" inverted>
                <Container fluid>
                    <Menu.Item>
                        <Image src={logo} className='logo'/>
                    </Menu.Item>
                    <Menu.Item as={Link} to="/" header className='navBarTitle'>
                        Synapse
                    </Menu.Item>
                    <Menu.Item>
                        <Search
                            size="small"
                            placeholder="Search Modules"
                            value={searchFilter}
                            onSearchChange={(event, {value}) => setSearchFilter(value)}
                            results={searchResults}
                            resultRenderer={service =>
                                <List>
                                    <List.Item as={Link} to={service.external ? {pathname: service.path} : service.path} target={service.external ? "_blank" : ""}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                                                    {
                                                        service.ipFiltered === true ?
                                                            <Popup
                                                                content="Access to this module is IP-filtered. You must be inside the WarnerMedia network in order to perform any actions."
                                                                trigger={
                                                                    <Icon.Group size="large" verticalAlign="middle">
                                                                        <Icon name={service.icon} verticalAlign="middle"/>
                                                                        <Icon corner name="lock" color="red"/>
                                                                    </Icon.Group>
                                                                }
                                                            /> :
                                                            <List.Icon name={service.icon} size="large" verticalAlign="middle"/>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column width={14}>
                                                    <List.Content>
                                                        <List.Header>
                                                            {service.name}   {service.environment !== null ? <Label size="tiny">{service.environment}</Label> : ""}
                                                        </List.Header>
                                                        <List.Description>{service.description}</List.Description>
                                                    </List.Content>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <br/>
                                        </Grid>
                                    </List.Item>
                                </List>
                            }
                        />
                    </Menu.Item>
                    <Menu.Item position="right">
                        <Dropdown item text={<div>Welcome, {givenName}!<br />{environment} v{process.env.VERSION}</div>}>
                            <Dropdown.Menu>
                                <Dropdown.Header>Environment</Dropdown.Header>
                                {environment.toLowerCase() !== "dev" ? <Dropdown.Item as="a" href="https://dev.synapse.ngtv.io">DEV</Dropdown.Item> : ""}
                                {environment.toLowerCase() !== "ref" ? <Dropdown.Item as="a" href="https://ref.synapse.ngtv.io">REF</Dropdown.Item> : ""}
                                {environment.toLowerCase() !== "ite" ? <Dropdown.Item as="a" href="https://ite.synapse.ngtv.io">ITE</Dropdown.Item> : ""}
                                {environment.toLowerCase() !== "qa" ? <Dropdown.Item as="a" href="https://qa.synapse.ngtv.io">QA</Dropdown.Item> : ""}
                                {environment.toLowerCase() !== "prod" ? <Dropdown.Item as="a" href="https://synapse.ngtv.io">PROD</Dropdown.Item> : ""}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Container>
            </Menu>
            {
                selectedService &&
                    <Menu inverted color="grey">
                        <Menu.Item>
                            <Breadcrumb>
                                <br /><br />
                                <Breadcrumb.Section>Home</Breadcrumb.Section>
                                <Breadcrumb.Divider icon="right chevron" />
                                <Breadcrumb.Section>{selectedGroup}</Breadcrumb.Section>
                                <Breadcrumb.Divider icon="right chevron" />
                                <Breadcrumb.Section>{selectedService}</Breadcrumb.Section>
                            </Breadcrumb>
                        </Menu.Item>
                    </Menu>
            }
        </Container>
    )
}

