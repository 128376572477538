import React, { useState, useEffect, Component } from "react";
import PropTypes from "prop-types";
import {Icon, Message, Tab, Container} from 'semantic-ui-react'

export default function SecureTabPane(props) {
    const [isAuthenticated, setIsAuthenticated] = useState("initializing");

    useEffect(() => {
        console.log("SecureTabPane.updateProps props: ", props);
    }, []);


    useEffect(function determineAuthorization() {
        let { user, authState, open, userPermissions, loadingPermissions, service, module, scope, role } = props;
        console.log(`SecureTabPane.determineAuthorization: authState ${authState}, open ${open}, loading permissions ${loadingPermissions}, urn:${service}:${module}:${scope}:${role}`);

        if (user !== undefined && user.hasOwnProperty("authz") && Array.isArray(user.authz) && !["UNINITIALIZED", true].includes(loadingPermissions) && Array.isArray(userPermissions)) {
            let userHasRequiredPermissions = open;
            if (!userHasRequiredPermissions) {
                for (const permission of userPermissions) {
                    const userHasServiceAccess = ["all", service].includes(permission.service);
                    const userHasModuleAccess = ["all", module].includes(permission.module);
                    const userHasScopeAccess = scope === undefined || ["all"].concat(scope).includes(permission.scope);
                    const userHasRoleAccess = role === undefined || ["aspen-power", role].includes(permission.role);
                    console.log(`SecureTabPane.determineAuthorization: user has ${service} service access? `, userHasServiceAccess, `${module} module access? `, userHasModuleAccess, `${scope} scope access? `, userHasScopeAccess, `${role} role access? `, userHasRoleAccess, "permission checked: ", permission);
                    if (userHasServiceAccess && userHasModuleAccess && userHasScopeAccess && userHasRoleAccess) {
                        userHasRequiredPermissions = true;
                        break;
                    }
                }

                console.log(`(SecureTabPane useEffect getAuthorization) authState ${authState}, user has required permissions: ${userHasRequiredPermissions}`);
            }

            let authenticated = authState !== "UNAUTHORIZED" && userHasRequiredPermissions;
            console.log("(SecureTabPane useEffect getAuthorization) setting isAuthenticated: ", authenticated);
            setIsAuthenticated(authenticated);
        }
    }, [isAuthenticated, JSON.stringify(props.user), props.authState, JSON.stringify(props.userPermissions), props.loadingPermissions, props.service, props.module, props.scope, props.role, props.open]);


    if (isAuthenticated === true) {
        console.log("(SecureTabPane render) authState was AUTHORIZED and user had the correct permissions, rendering: ", props.component.name);
        return (
            <Tab.Pane>
                {props.component}
            </Tab.Pane>
        )

    } else if (isAuthenticated === false) {
        if (props.authState === "AUTHORIZED") {
            console.log(`(SecureTabPane render) authState was AUTHORIZED, but user was not authenticated: isAuthenticated ${isAuthenticated}, authState ${props.authState}`);
            return (
                <Container>
                    <Message color="yellow" icon>
                        <Icon name="exclamation"/>
                        <Message.Content>You do not have adequate permissions to view the contents of this tab.</Message.Content>
                    </Message>
                </Container>
            )
        } else {
            console.log("(SecureTabPane render) user, isAuthenticated, authState user not authenticated, authstate not authorized", props.user, isAuthenticated, props.authState);
            return (
                <Message icon color="yellow">
                    <Icon name="exclamation" />
                    <Message.Content>
                        You do not have permission to view this tab.
                    </Message.Content>
                </Message>
            );
        }
    } else {
        console.log("(SecureTabPane render) user not authenticated, isAuthenticated, authState", props.user, isAuthenticated, props.authState);
        return <br />
    }
}

SecureTabPane.propTypes = {
    component: PropTypes.objectOf(React.Component) || PropTypes.func,
    getAllPotentialProperties: PropTypes.func,
    user: PropTypes.object.isRequired,
    authenticated: PropTypes.bool.isRequired,
    checkIfAuthorized: PropTypes.func.isRequired,
    listToDatalist: PropTypes.func,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    open: PropTypes.bool,
    userPermissions: PropTypes.array.isRequired,
    loadingPermissions: PropTypes.bool.isRequired
};

SecureTabPane.defaultProps = {
    open: false
};
