import React from "react";
import { Input } from "semantic-ui-react";

class PasswordInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "password",
            password: this.props.value
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let pickedType;
        if (this.state.type === "text"){
            pickedType = "password";
        }else{
            pickedType = "text";
        }
        this.setState(state => ({
            type: pickedType
        }));
    }

    render() {
        return (
            <Input
                action={{
                    icon: "eye",
                    onClick: () => this.handleClick()
                }}
                actionPosition="left"
                type={this.state.type}
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder="Secret"
            />
        );
    }
}

export default PasswordInput;
