import React, {useState, useEffect} from "react";
import {Container, Grid, Icon, Message} from "semantic-ui-react";
import DashboardColumn from "./DashboardColumn";
import AuthDataProvider from "../../Services/AuthDataProvider";

const ROWS_REMAINING = 15;

export default function Dashboard(props) {
    const [qualifiedServices, setQualifiedServices] = useState([]);
    const [initialRowsRemaining, setInitialRowsRemaining] = useState(ROWS_REMAINING);
    const [columns, setColumns] = useState([]);

    useEffect(function determineQualifiedServices() {
        const authorizedServiceGroups = [];
        let totalServices = 0;

        if (props.user) {
            for (const serviceGroup of props.serviceGroups) {
                const authorizedServiceGroup = {};
                authorizedServiceGroup.groupName = serviceGroup.groupName;
                authorizedServiceGroup.services = serviceGroup.services.filter(service => {
                    console.log("Dashboard.determineQualifiedServices: service: ", service)
                    return AuthDataProvider.userHasRequiredPermission(props.user.authz, props.permissions,service.open || false, service.service, service.module, service.scope, service.role) === true
                });
                if (authorizedServiceGroup.services.length > 0) {
                    authorizedServiceGroups.push(authorizedServiceGroup);
                    totalServices += authorizedServiceGroup.services.length;
                }
            }
        }

        setQualifiedServices(authorizedServiceGroups);
        setInitialRowsRemaining(Math.floor(totalServices / 4));
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    useEffect(function setColumnsFromQualifiedServices() {
        if (qualifiedServices.length > 0) {
            let currentRowsRemaining = initialRowsRemaining;
            let serviceColumns = [[]];
            for (const serviceGroup of qualifiedServices) {
                console.log("(DashboardWithGroups.setColumnsFromQualifiedServices) serviceGroup: ", serviceGroup, "current rows remaining: ", currentRowsRemaining);
                serviceColumns[serviceColumns.length - 1].push(serviceGroup);
                currentRowsRemaining -= serviceGroup.services.length;
                if (currentRowsRemaining <= 0) {
                    serviceColumns.push([]);
                    currentRowsRemaining = initialRowsRemaining;
                }
            }
            setColumns(serviceColumns);
            console.log("(DashboardWithGroups.setColumnsFromQualifiedServices) serviceColumns:", serviceColumns);
        }
    }, [qualifiedServices]);

    const generateColumns = () => {
        let generatedColumns = columns.map((column, index) => {
            console.log("(DashboardWithGroups.generateColumns generating column from ", column);
            return <DashboardColumn key={`column-${index}`} column={column} />;
        });
        console.log("(DashboardWithGroups.generateColumns) generatedColumns:", generatedColumns);
        return generatedColumns;
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row />
            {
                props.loadingPermissions ?
                    <Container>
                        <Message icon color="yellow">
                            <Icon name="spinner" loading />
                            <Message.Content>
                                Loading...
                            </Message.Content>
                        </Message>
                    </Container> :
                    <Grid.Row centered>
                        {generateColumns()}
                    </Grid.Row>
            }
        </Grid>
    );
}
