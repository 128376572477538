import React from "react";
import {Grid} from "semantic-ui-react";
import DashboardGroup from "./DashboardGroup";

export default function DashboardColumn(props) {
    const generateColumn = () => {
        console.log("(DashboardColumn.generateColumn) generating column from ", props.column);
        let column = props.column.map((serviceGroup, index) => {
            console.log("(DashboardColumn.generateColumn generating column from service group", serviceGroup);
            return <DashboardGroup
                key={`dashboard-group-${index}`}
                groupName={serviceGroup.groupName}
                services={serviceGroup.services}
            />;
        });

        console.log("(DashboardColumn.generateColumn) generated column: ", column);

        return column;
    };

    return (
        <Grid.Column width={4}>
            {generateColumn()}
        </Grid.Column>
    );
}
