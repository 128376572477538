import React from "react";
import {Segment, List, Header, Grid, Icon, Label, Popup} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default function DashboardGroup(props) {
    const generateListItem = service => {
        return (
            <List.Item key={service.name} as={Link} to={service.external ? {pathname: service.path} : service.path} target={service.external ? "_blank" : ""}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                            {
                                service.ipFiltered ?
                                    <Popup
                                        content="Access to this module is IP-filtered. You must be inside the WarnerMedia network in order to perform any actions."
                                        trigger={
                                            <Icon.Group size="large" verticalAlign="middle">
                                                <Icon name={service.icon} verticalAlign="middle"/>
                                                <Icon corner name="lock" color="red"/>
                                            </Icon.Group>
                                        }
                                    /> :
                                    <List.Icon name={service.icon} size="large" verticalAlign="middle" flipped={service.flipped || false}/>
                            }
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <List.Content>
                                <List.Header>
                                    {service.name}   {service.environment !== null ? <Label size="tiny">{service.environment}</Label> : ""}
                                </List.Header>
                                <List.Description>{service.description}</List.Description>
                            </List.Content>
                        </Grid.Column>
                    </Grid.Row>
                    <br/>
                </Grid>
            </List.Item>
        );
    }

    const generateListItems = () => {
        let listItems = props.services.map(generateListItem);
        console.log("(DashboardGroup.generateListItems listItems: ", listItems);
        return listItems;
    };

    return (
        <Segment>
            <Header>{props.groupName}</Header>
            <List>
                {generateListItems()}
            </List>
        </Segment>
    );
}
