import React, {useEffect, useState} from "react";
import {Button, Icon, Modal, Header, ModalContent, ModalActions} from "semantic-ui-react";

export default function BasicModal(props) {
    return (
        <Modal
            open={props.open}
            onClose = {() => props.handleOnClose}
            onOpen = {() => props.handleOnOpen}
            header = {props.headerText}
            content = {props.modalContent}
            trigger = {props.trigger}
        >
            <Header>
                {props.headerText}
            </Header>
            <ModalContent>
                <p>
                    {props.modalContent}
                </p>
            </ModalContent>
            <ModalActions>
                <Button color='red' onClick={() => props.onClose()}>
                <Icon name='remove' /> No
                </Button>
                <Button color='green' onClick={() => props.onOpen()}>
                <Icon name='checkmark' /> Yes
                </Button>
            </ModalActions>
        </Modal>   
    )
}
