import React, {useState, useEffect} from "react";
import {Table, Input, Select, Button, Form, Checkbox, Icon, Popup} from "semantic-ui-react";

export default function MetadataTableRow(props) {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isInIdList, setIsInIdList] = useState(false);

    useEffect(function updateIsReadOnly() {
        if (props.property.key === "_id") {
            setIsReadOnly(!(props.newClicked || props.duplicateClicked));
        } else {
            setIsReadOnly(props.property.isLocked === true);
        }
    }, [JSON.stringify(props.property), props.newClicked, props.duplicateClicked]);

    useEffect(function isIdAndIdIsInList() {
        let updatedIdIsInList = false;
        if (props.property && props.property.key === "_id" && Array.isArray(props.idList)) {
            for (const id of props.idList) {
                if (props.property.value.toLowerCase() === id.toLowerCase()) {
                    updatedIdIsInList = true;
                    break;
                }
            }
        }
        setIsInIdList(updatedIdIsInList);
    }, [JSON.stringify(props.property), JSON.stringify(props.idList)]);

    useEffect(function disableSaving() {
        if (props.hasOwnProperty("setDisableSaving")) {
            props.setDisableSaving(isInIdList && (props.newClicked || props.duplicateClicked));
        }
    }, [isInIdList]);

    return (
        <Table.Row verticalAlign="top" negative={props.property.key !== "_id" && props.property.highlight}>
            <Table.Cell width={1}>
                <Form.Field>
                    <Button icon="minus" color="black" onClick={() => props.removeProperty(props.index)} disabled={props.property.key === "_id" || props.property.isLocked || props.property.lockType}/>
                </Form.Field>
            </Table.Cell>
            <Table.Cell>
                <Input
                    value={props.property.key}
                    onChange={(event, {value}) => props.updatePropertyKey(props.index, value)}
                    fluid
                    required
                    disabled={props.property.key === "_id" || isReadOnly || props.property.lockType}
                />
            </Table.Cell>
            <Table.Cell>
                <Select
                    options={props.DATA_TYPES}
                    value={props.property.type}
                    onChange={(event, {value}) => props.updatePropertyValue(props.index, props.property.value, value)}
                    fluid
                    required
                    disabled={props.property.key === "_id" || isReadOnly || props.property.lockType}
                />
            </Table.Cell>
            <Table.Cell>
                {
                    props.property.type === "array" && Array.isArray(props.property.value) ?
                        <Table basic="very" compact>
                            <Table.Body>
                                {
                                    props.property.value.map((value, index) => {
                                        return (
                                            <Table.Row key={`${props.property.key}-table-row-${index}`}>
                                                <Form.Field>
                                                    <Form>
                                                        <Form.Group widths="equal">
                                                            <Form.Field width={1}>
                                                                <Button
                                                                    icon="minus"
                                                                    color="black"
                                                                    onClick={() => {
                                                                        const updatedValue = props.property.value.slice();
                                                                        updatedValue.splice(index);
                                                                        props.updatePropertyValue(props.index, updatedValue, props.property.type);
                                                                    }}
                                                                    disabled={isReadOnly}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Input
                                                                    value={value}
                                                                    onChange={(event, {value}) => {
                                                                        const updatedValue = [];
                                                                        for (let i = 0; i < props.property.value.length; i++) {
                                                                            updatedValue.push(i === index ? value : props.property.value[i]);
                                                                        }
                                                                        props.updatePropertyValue(props.index, updatedValue, props.property.type)}
                                                                    }
                                                                    fluid
                                                                    disabled={isReadOnly}
                                                                    required
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Form>
                                                </Form.Field>
                                            </Table.Row>
                                        );
                                    })
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <Form.Field>
                                            <Button
                                                onClick={
                                                    () => {
                                                        let newValue = props.property.value;
                                                        newValue.push("");
                                                        console.log(`updating array from ${JSON.stringify(props.property.value)} to ${JSON.stringify(newValue)}`);
                                                        props.updatePropertyValue(props.index, newValue, props.property.type)
                                                    }
                                                }
                                                fluid
                                                disabled={isReadOnly}
                                            ><Icon name="plus"/>Add Item</Button>
                                        </Form.Field>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                    props.property.type === "object" ?
                        <Table basic="very" compact>
                            <Table.Body>
                                {
                                    Object.keys(props.property.value).map((key, index) =>
                                        <Table.Row key={`${props.property.key}-table-row-${index}`}>
                                            <Form.Field>
                                                <Form>
                                                    <Form.Group widths="equal">
                                                        <Form.Field width={1}>
                                                            <Button
                                                                color="black"
                                                                icon="minus"
                                                                onClick={() => {
                                                                    const {[key]: deletedKey, ...updatedValue} = props.property.value;
                                                                    props.updatePropertyValue(props.index, updatedValue, props.property.type);
                                                                }}
                                                                disabled={isReadOnly}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Input
                                                                value={key}
                                                                onChange={(event, {value}) => {
                                                                    const updatedValue = {};
                                                                    const keys = Object.keys(props.property.value);
                                                                    for (let i = 0; i < keys.length; i++) {
                                                                        const key = keys[i];
                                                                        updatedValue[i === index ? value : key] = props.property.value[key];
                                                                    }
                                                                    props.updatePropertyValue(props.index, updatedValue, props.property.type)}
                                                                }
                                                                fluid
                                                                disabled={isReadOnly}
                                                                required
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Input
                                                                value={props.property.value[key]}
                                                                onChange={(event, {value}) => {
                                                                    const updatedValue = Object.assign({}, props.property.value);
                                                                    updatedValue[key] = value;
                                                                    props.updatePropertyValue(props.index, updatedValue, props.property.type)}
                                                                }
                                                                disabled={isReadOnly}
                                                                required
                                                                fluid
                                                            />
                                                        </Form.Field>
                                                    </Form.Group>
                                                </Form>
                                            </Form.Field>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <Form.Field>
                                            <Button
                                                onClick={() => {
                                                    let updatedValue = typeof props.property.value === "string" ? {} : props.property.value;
                                                    updatedValue[`new-key-${Object.keys(props.property.value).length}`] = "";
                                                    props.updatePropertyValue(props.index, updatedValue, "object")}
                                                }
                                                fluid
                                                disabled={isReadOnly}
                                            ><Icon name="plus"/>Add Item</Button>
                                        </Form.Field>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                    props.property.type === "boolean" ?
                        <Form.Field>
                            <Form>
                                <Form.Field
                                    label="&nbsp;"
                                    control={Checkbox}
                                    toggle
                                    checked={!!props.property.value}
                                    onClick={(event, {checked}) => props.updatePropertyValue(props.index, checked, props.property.type)}
                                    disabled={isReadOnly}
                                />
                            </Form>
                        </Form.Field> :
                        <Popup
                            open={isInIdList && (props.newClicked || props.duplicateClicked)}
                            content="This key is already in use."
                            color="red"
                            trigger={
                                <Input
                                    value={props.property.value}
                                    onChange={(event, {value}) => {
                                        console.log(props.idList)
                                        props.updatePropertyValue(props.index, value, props.property.type)}
                                    }

                                    fluid
                                    disabled={isReadOnly}
                                    required
                                />
                            }
                        />
                }
            </Table.Cell>
            {
                props.property.readonly !== undefined && typeof props.updatePropertyReadOnly === "function" ?
                    <Table.Cell>
                        <Form.Field
                            label="&nbsp;"
                            control={Checkbox}
                            toggle
                            checked={!!props.property.readonly}
                            onClick={(event, {checked}) => props.updatePropertyReadOnly(props.index, checked)}
                        />
                    </Table.Cell> : ""
            }
        </Table.Row>
    );
};
