import React, {Component} from 'react';
import {Grid, Button} from "semantic-ui-react";
import error404 from './images/error404.png';
import { Link } from "react-router-dom";


class NotFound extends React.Component  {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <Grid.Column width={16} className="masterContainer" inverted>
                <Grid >
                <Grid.Row centered Columns={8}> </Grid.Row>
                    <Grid.Row centered Columns={8}>
                        <img src={error404} alt="404 - Page Not Found" />
                    </Grid.Row>
                    <Grid.Row centered Columns={8}> </Grid.Row>
                    <Grid.Row centered Columns={8}>
                        <Link to="/">
                            <Button color="blue"> Go Home </Button>
                        </Link>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
    }
}

export default NotFound;
